import React from 'react'
import './App.css';
import water1 from './prodimg/water/water1.jpg'
import water2 from './prodimg/water/water2.jpg'
import water3 from './prodimg/water/water3.jpg'
import water4 from './prodimg/water/water4.jpg'
import water5 from './prodimg/water/water5.jpg'
import water6 from './prodimg/water/water6.jpg'
import water7 from './prodimg/water/water7.jpg'
import water8 from './prodimg/water/water8.jpg'
import water9 from './prodimg/water/water9.jpg'
import water10 from './prodimg/water/water10.jpg'
import water11 from './prodimg/water/water11.jpg'
import water12 from './prodimg/water/water12.jpg'
import water13 from './prodimg/water/water13.jpg'
import water14 from './prodimg/water/water14.jpg'
import water15 from './prodimg/water/water15.jpg'
import water16 from './prodimg/water/water16.jpg'
import water17 from './prodimg/water/water17.jpg'
import water18 from './prodimg/water/water18.jpg'
import water19 from './prodimg/water/water19.jpg'
import water20 from './prodimg/water/water20.jpg'

export default function WaterSeparator() {
    return (
        <div className='prod-container'>
            <div className="heading">
                <h3>Water Separator</h3>
            </div>
            <div className="card-container">
                <div className="card" style={{ width: '18rem' }}>
                    <img src={water1} alt="water1" />
                    <div className="card-body">
                        <h6 className="card-title">FSM-275</h6>
                        <p className="card-text">
                            TC-AMW Water Separate Small <br />
                            Nut EQV REF. NO. FS 1275 <br />
                            278607989916<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={water2} alt="water2" />
                    <div className="card-body">
                        <h6 className="card-title">FSM-306</h6>
                        <p className="card-text">
                            DIESEL WATER SEPARATOR 1613<br />
                            EQV REF. NO.<br />
                            F 002 M 20387 BOSCH<br /></p>
                    </div>
                </div><div className="card" style={{ width: '18rem' }}>
                    <img src={water3} alt="water3" />
                    <div className="card-body">
                        <h6 className="card-title">FSM-644V</h6>
                        <p className="card-text">
                            WATER DIESEL SEPARATOR TATA<br />
                            EQV REF. NO. FS 19644V 252509120239<br />
                            ID 320451, 8050107 Id364170, ID366523<br /></p>

                    </div>
                </div><div className="card" style={{ width: '18rem' }}>
                    <img src={water4} alt="water4" />
                    <div className="card-body">
                        <h6 className="card-title">FSM-636</h6>
                        <p className="card-text">
                            WATER DIESEL SEPARATOR LEYLAND<br />
                            BS II & BS III<br />
                            EQV REF.NO. FS 19636,<br />
                            F 8835100<br /></p>

                    </div>
                </div><div className="card" style={{ width: '18rem' }}>
                    <img src={water5} alt="water5" />
                    <div className="card-body">
                        <h6 className="card-title">FSM-660</h6>
                        <p className="card-text">
                            WATER SEPARATOR WITH OUT<br />
                            AUTO DRAIN VALVE<br />
                            TATA 1109 ULTRA<br />
                            EQV. REF. NO. FS 19660<br />
                            252709120259
                            8050134<br /></p>
                    </div>
                </div><div className="card" style={{ width: '18rem' }}>
                    <img src={water6} alt="water6" />
                    <div className="card-body">
                        <h6 className="card-title">FSM-732</h6>
                        <p className="card-text">
                            WATER SEPARATOR<br />
                            TATA PRIMA/ISBE ENGINE <br />
                            EQV REF. NO. FS 19732, <br />
                            288047704904, 3973233<br /></p>
                    </div>
                </div><div className="card" style={{ width: '18rem' }}>
                    <img src={water7} alt="water7" />
                    <div className="card-body">
                        <h6 className="card-title">FSM-634</h6>
                        <p className="card-text">
                            FUEL WATER SEPARATOR<br />
                            FOR EICHER<br />
                            EQV REF.NO. Fs19634,<br />
                            4962810 ID320704<br /></p>
                    </div>
                </div><div className="card" style={{ width: '18rem' }}>
                    <img src={water8} alt="water8" />
                    <div className="card-body">
                        <h6 className="card-title">FSM-662</h6>
                        <p className="card-text">
                            WATER SEPARATOR WITH OUT<br />
                            AUTO DRAIN VALVE<br />
                            TATA 3118<br />
                            EQV. REF. NO. FS 19662<br />
                            278609110106 500549<br /></p>
                    </div>
                </div><div className="card" style={{ width: '18rem' }}>
                    <img src={water9} alt="water9" />
                    <div className="card-body">
                        <h6 className="card-title">FFM-555</h6>
                        <p className="card-text">
                            Fuel filter for 2515 TO <br />
                            4018B5.9 ISBe BS IV<br />
                            EQV REF. NO 278609999828, <br />
                            5403508 Ff5551<br /></p>
                    </div>
                </div><div className="card" style={{ width: '18rem' }}>
                    <img src={water10} alt="water11" />
                    <div className="card-body">
                        <h6 className="card-title">FSM-701</h6>
                        <p className="card-text" style={{ fontSize: '12px' }}>
                            FWS for XYLO,
                            ASHOK LEYLAND PARTNER,
                            MITR BUS(NALT ZD 30 TCIC BS
                            III & BSIV ENGINES),
                            KOEL ENGINES FOR MMVR
                            III BACKHOLE LOADER (CAT,
                            L&T CASE, TEREX,
                            ECL & ACE), EQV.
                            REF NO. F002H22012</p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={water11} alt="water11" />
                    <div className="card-body">
                        <h6 className="card-title">FSM-700</h6>
                        <p className="card-text">
                            FUEL WATER SEPARATOR <br />
                            FOR ASHOK LEYLAND 3118<br />
                            EQV REF NO FS19669, <br />
                            5010516, FHJ 00 700<br /></p>

                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={water12} alt="water12" />
                    <div className="card-body">
                        <h6 className="card-title">FSM-UT-003</h6>
                        <p className="card-text">
                            LEYLAND CRS/<br />
                            U TRUCK WATER SEPERATOR<br />
                            EQV REF. NO. F 002, <br />
                            H 22004 P1302140<br /></p>
                    </div>
                </div><div className="card" style={{ width: '18rem' }}>
                    <img src={water13} alt="water13" />
                    <div className="card-body">
                        <h6 className="card-title">FSM-400</h6>
                        <p className="card-text">
                            FUEL WATER SEPARATOR WITHOUT <br />
                            DRAIN VALVE FOR ASHOK LEYLAND <br />
                            Cb18 FIP CRS ENGINE<br />
                            QV. Ref No. FS19671, FHN0400<br /></p>
                    </div>
                </div><div className="card" style={{ width: '18rem' }}>
                    <img src={water14} alt="water14" />
                    <div className="card-body">
                        <h6 className="card-title">FSM-140</h6>
                        <p className="card-text" style={{ fontSize: '12px' }}>
                            FUEL FILTER<br />
                            WATER SEPARATOR For <br />
                            DAIMLER OR BHARAT BENZ <br />
                            1613,2523,3118,3116 <br />
                            EQV. Ref. No<br />
                            DC A 400 477 03 01<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={water15} alt="water15" />
                    <div className="card-body">
                        <h6 className="card-title">FSM-140 + CAP + HEAD</h6>
                        <p className="card-text" style={{ fontSize: '12px' }}>
                            FUEL FILTER<br />
                            WATER SEPARATOR For <br />
                            DAIMLER OR BHARAT BENZ <br />
                            1613,2523,3118,3116 <br />
                            EQV. Ref. No<br />
                            DC A 400 477 03 01<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={water16} alt="water16" />
                    <div className="card-body">
                        <h6 className="card-title">FSM-140 WITH CAP</h6>
                        <p className="card-text" style={{ fontSize: '12px' }}>
                            FUEL FILTER<br />
                            WATER SEPARATOR For <br />
                            DAIMLER OR BHARAT BENZ <br />
                            1613,2523,3118,3116<br />
                            EQV. Ref. No<br />
                            DC A 400 477 03 01<br /></p>

                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={water17} alt="water17" />
                    <div className="card-body">
                        <h6 className="card-title">FSM-657V</h6>
                        <p className="card-text" style={{ fontSize: '12.5px' }}>
                            WATER SEPARATOR<br />
                            for TATA TC with Drain Plug,
                            Pipe and Sensor Switch for
                            BSIII TATA 31182515EX-2516EX
                            3516EX-4018EX-3018EX
                            EQV. Ref. No.FS-19657V
                            WITH SENSOR 5292575,
                            5293068, 278607989965<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={water18} alt="water18" />
                    <div className="card-body">
                        <h6 className="card-title">FSM-003</h6>
                        <p className="card-text">
                            FUEL WATER SEPARATOR FOR <br />
                            ASHOK LEYLAND NEPTUNE ENGINE<br />
                            EQV Ref. No. Fs1003, Fs19654, <br />
                            F7B02900 220747709902<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={water19} alt="water19" />
                    <div className="card-body">
                        <h6 className="card-title">FSM-095</h6>
                        <p className="card-text">
                            Diesel Filter (Spin On)<br />
                            for Tata 1109 Ultra<br />
                            F002H22095,<br />
                            252709119902<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={water20} alt="water20" />
                    <div className="card-body">
                        <h6 className="card-title">FSM-641</h6>
                        <p className="card-text">
                            FULE WATER SEPARATOR <br />
                            EQV REF. NO.<br />
                            FS19641/
                            288047704904
                            01492932</p>
                    </div>
                </div>
            </div>
            <div className="note">
                <p>All the OEM numbers in this page are only for reference</p>
            </div>
        </div>
    )
}
