import React from 'react';
import './App.css'
import { YearCounter, CustomersCounter, PartsAvailabilityCounter } from './Counters';
import logomain from './logomain.png';
import Elements from './Elements';

export default function About() {
    return (
        <>
            <div className="main-about">
                <div className='bgimg'>
                </div>
                <div className="container-about">
                    <h2 className='h2text'>What do we do?</h2>
                    <p className='h2para'> "Microguard Filters Corporation has emerged as a leader in the filtration industry. We are committed to delivering superior quality filters, ranging from precision-engineered oil filters to high-performance air and fuel filters. Our mission is to provide innovative and sustainable solutions that enhance the efficiency and longevity of your equipment. Explore our comprehensive product line and experience the difference of Microguard Filters Corporation filters today!"</p>
                    <h2 className="h2text">Why choose us?</h2>
                    <p className='h2para'>"The most important reason for you to choose us is that we provide our customers with their custom designed filters. Choosing Microguard Filters Corporation for your filtration needs is a decision backed by years of expertise and a steadfast commitment to excellence. Our filters are meticulously designed and manufactured to meet the highest industry standards, ensuring optimal performance and longevity. With a proven track record of customer satisfaction, we pride ourselves on providing reliable, cost-effective solutions tailored to your specific requirements. Additionally, our dedication to sustainability means you can trust that our products not only protect your equipment, but also contribute to a cleaner, greener future. Experience the difference with Microguard Filters Corporation – where quality meets innovation."</p>
                </div>
                <Elements />
                <div className="logocontainer">
                    <img src={logomain} alt="logomain" className="logomain" />
                    <div className="logotext">"Our motto is to serve our customers with the best quality filters and provide advanced expert service."</div>

                </div>

                <YearCounter />
                <CustomersCounter />
                <PartsAvailabilityCounter />
            </div>
        </>
    )
}
