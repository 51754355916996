import React from 'react';
import './App.css';
import logo from './logo3.jpg';
import { Link } from 'react-router-dom';




export default function Footer() {
    return (
        <footer>
            <div className="footer-content">
                <div className="footer-logo">
                    <img src={logo} alt="Company Logo" />
                </div>
                <div className="footer-column">
                    <h4>Contact Us</h4>
                    <p>Email: microguardfilters@gmail.com</p>
                    <p>Phone: +91 9029648436</p>
                </div>
                <div className="footer-column">
                    <h4>Quick Links</h4>
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/about">About Us</Link></li>
                        <li><Link to="/contact">Contact</Link></li>
                    </ul>
                </div>
                <div className="footer-column">
                    <h4>MICROGUARD FILTERS CORPORATION</h4>
                    <ul>
                        <li style={{ fontSize: '12px' }}>317,FLOOR GRD, PLOT NO -315 GIRIRAJ RESIDENCY, C.H.S VITTHAL BHAI PATEL ROAD, DO HATTI GALI, near AYODHYA RESTAURANT, Opera House, Girgaon, Mumbai, Maharashtra 400004</li>
                        <li>

                            <i className="far fa-envelope"></i> {/* Add a mail icon here */}
                            <a href="mailto:microguardfilters@gmail.com" style={{ marginLeft: '2px', fontSize: '15px', textDecoration: 'none' }}>
                                microguardfilters@gmail.com
                            </a>


                        </li>
                        <li>

                            <i className="fas fa-phone"></i> {/* Add the phone icon */}
                            <a href="tel:+919029648436" style={{ marginLeft: '5px', textDecoration: 'none' }}>+91 9029648436</a>


                        </li>
                        <li><i className="fas fa-phone"></i> {/* Add the phone icon */}
                            <a href="tel:+919029648436" style={{ marginLeft: '5px', textDecoration: 'none' }}>+91 9820621931</a>
                        </li>
                    </ul>
                </div>
                {/* <div className="footer-column">
                    <h4>Follow Us</h4>
                    <p>Stay connected with us on social media:</p>
                    <ul className="social-icons">
                        <li><a href="/"><i className="fab fa-facebook"></i></a></li>
                        <li><a href="/"><i className="fab fa-twitter"></i></a></li>
                        <li><a href="/"><i className="fab fa-instagram"></i></a></li>
                    </ul>
                </div> */}
            </div>
            <div className="footer-divider"></div>
            <div className="footer-copyright">
                &copy; {new Date().getFullYear()} MICROGUARD FILTERS CORPORATION. All rights reserved.
            </div>
        </footer>
    );
}
