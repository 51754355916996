import React from 'react'
import './App.css'
import crane from './crane.jpg'
import ship from './ship.jpg'
import heavyloaders from './heavyloaders.jpg'
import truck1 from './truck1.jpg'

export default function Elements() {
    return (
        <div>
            <h2 className='ipoh2'>Types of Filters We Stock</h2>
            {/* <ul>
                <li>Automobile Filters</li>
                <li>Marine Filters</li>
                <li>Industrial Filters</li>
                <li>Heavy Earth Mover Filters</li>
                <li>Filter as per Sample</li>
                <li>Filter as per Drawing</li>
                <li>Transmission Filters</li>
            </ul> */}
            <div className="icon-container">
                <div className="ipo">
                    <img src={truck1} alt="truck1" className="ellogo1" />
                    <h4 className='ipotext'>Automobile</h4>
                </div>
                <div className="ipo">
                    <img src={crane} alt="crane" className="ellogo" />
                    <h4 className='ipotext1'>Industrial</h4>
                </div>
                <div className="ipo">
                    <img src={ship} alt="ship" className="ellogo" />
                    <h4 className='ipotext1'>Marine</h4>
                </div>
                <div className="ipo">
                    <img src={heavyloaders} alt="Logo3" className="ellogo" />
                    <h4 className='ipotext1'>Heavy Earth Mover</h4>
                </div>
            </div>
            <h4 className='morefilters'>We also make Filters as per Sample and Drawing</h4>
        </div>
    )
}
