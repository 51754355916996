import React from 'react'
import './details.css'
import { Link } from 'react-router-dom';
import fuelprocess from './fuelprocess.jpg'
import 'slick-carousel/slick/slick.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the styles
import { Carousel } from 'react-responsive-carousel';
import 'slick-carousel/slick/slick-theme.css';
import fuel1 from './prodimg/fuel/fuel1.jpg'
import fuel2 from './prodimg/fuel/fuel2.jpg'
import fuel3 from './prodimg/fuel/fuel3.jpg'
import fuel4 from './prodimg/fuel/fuel4.jpg'
import fuel5 from './prodimg/fuel/fuel5.jpg'
import fuel6 from './prodimg/fuel/fuel6.jpg'
import fuel7 from './prodimg/fuel/fuel7.jpg'
import fuel8 from './prodimg/fuel/fuel8.jpg'
import fuel9 from './prodimg/fuel/fuel9.jpg'
import fuel10 from './prodimg/fuel/fuel10.jpg'
import fuel11 from './prodimg/fuel/fuel11.jpg'
import fuel12 from './prodimg/fuel/fuel12.jpg'

export default function Details3() {
    return (
        <div>
            <div className="filter-details">
                <h2>Fuel Filters</h2>

                <div className="information">
                    <h3>Product Information</h3>
                    <p>
                        Fuel filters are crucial components in internal combustion engines that help maintain the proper functioning of the fuel system. They are designed to remove impurities, contaminants, and particles from the fuel before it reaches the engine. These impurities can include dirt, debris, rust, and other substances that might be present in the fuel supply.
                        <br />
                        By preventing these contaminants from entering the engine, fuel filters help ensure optimal performance and longevity. They also play a vital role in protecting sensitive components such as fuel injectors and carburetors from damage.
                    </p>
                    <h4>How does Fuel Filter work?</h4>
                    <ol>

                    </ol>
                    <img src={fuelprocess} alt="fuelprocess" className="process" />
                </div>

                <div className="specifications">
                    <h4>Summary</h4>
                    <ul>
                        <li>Fuel filters are designed to remove impurities and contaminants from the fuel before it reaches the engine.</li>
                        <li>They play a crucial role in protecting sensitive engine components like fuel injectors and carburetors from damage caused by debris and contaminants.</li>
                        <li>By ensuring clean fuel supply, fuel filters contribute to optimal engine performance, leading to smoother operation and improved fuel efficiency.</li>
                        <li>Over time, fuel filters accumulate debris and become clogged. Regular replacement is necessary to maintain their effectiveness.</li>
                        <li>Fuel filters come in various types, including inline filters and cartridge filters, and are used in different types of vehicles, including cars, trucks, and motorcycles.</li>
                    </ul>
                </div>

                <div className="image-card-container">
                    <Carousel
                        autoPlay
                        interval={2000}
                        infiniteLoop
                        showThumbs={false}
                        showArrows={false}
                        centerMode
                        centerSlidePercentage={33.3}
                        emulateTouch
                        showStatus={false}
                        showIndicators={false} // Add this line to hide the sliding dots
                    >
                        <div className='image-card'>
                            <img src={fuel1} alt="" />
                        </div>
                        <div className='image-card'>
                            <img src={fuel2} alt="" />
                        </div>
                        <div className='image-card'>
                            <img src={fuel3} alt="" />
                        </div>
                        <div className='image-card'>
                            <img src={fuel4} alt="" />
                        </div>
                        <div className='image-card'>
                            <img src={fuel5} alt="" />
                        </div>
                        <div className='image-card'>
                            <img src={fuel6} alt="" />
                        </div>
                        <div className='image-card'>
                            <img src={fuel7} alt="" />
                        </div>
                        <div className='image-card'>
                            <img src={fuel8} alt="" />
                        </div>
                        <div className='image-card'>
                            <img src={fuel9} alt="" />
                        </div>
                        <div className='image-card'>
                            <img src={fuel10} alt="" />
                        </div>
                        <div className='image-card'>
                            <img src={fuel11} alt="" />
                        </div>
                        <div className='image-card'>
                            <img src={fuel12} alt="" />
                        </div>
                    </Carousel>
                </div>
            </div>
            <Link to="/FuelFilters">
                <h4 className='morefilters'>Click here to see all types of Fuel Filters</h4>
            </Link>
        </div>
    )
}
