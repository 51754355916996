import React from 'react'
import './App.css';
import oil1 from './prodimg/oil/oil1.jpg'
import oil2 from './prodimg/oil/oil2.jpg'
import oil3 from './prodimg/oil/oil3.jpg'
import oil4 from './prodimg/oil/oil4.jpg'
import oil5 from './prodimg/oil/oil5.jpg'
import oil6 from './prodimg/oil/oil6.jpg'
import oil7 from './prodimg/oil/oil7.jpg'
import oil8 from './prodimg/oil/oil8.jpg'
import oil9 from './prodimg/oil/oil9.jpg'
import oil10 from './prodimg/oil/oil10.jpg'
import oil11 from './prodimg/oil/oil11.jpg'
import oil12 from './prodimg/oil/oil12.jpg'
import oil13 from './prodimg/oil/oil13.jpg'
// import oil14 from './prodimg/oil/oil14.jpg'
import oil15 from './prodimg/oil/oil15.jpg'
import oil16 from './prodimg/oil/oil16.jpg'
import oil17 from './prodimg/oil/oil17.jpg'
import oil18 from './prodimg/oil/oil18.jpg'
import oil19 from './prodimg/oil/oil19.jpg'
import oil20 from './prodimg/oil/oil20.jpg'
import oil21 from './prodimg/oil/oil21.jpg'
import oil22 from './prodimg/oil/oil22.jpg'
import oil23 from './prodimg/oil/oil23.jpg'
import oil24 from './prodimg/oil/oil24.jpg'
import oil25 from './prodimg/oil/oil25.jpg'
import oil26 from './prodimg/oil/oil26.jpg'
import oil27 from './prodimg/oil/oil27.jpg'
import oil28 from './prodimg/oil/oil28.jpg'
import oil29 from './prodimg/oil/oil29.jpg'
import oil30 from './prodimg/oil/oil30.jpg'
import oil31 from './prodimg/oil/oil31.jpg'

export default function OilFilters() {
    return (
        <div>
            <div className="heading">
                <h3>Oil Filter</h3>
            </div>
            <div className="card-container">
                <div className="card" style={{ width: '18rem' }}>
                    <img src={oil1} alt="oil1" />
                    <div className="card-body">
                        <h6 className="card-title">OFM-UT-150</h6>
                        <p className="card-text">
                            LEYLAND U-TRUCK <br />
                            OIL FILTER <br />
                            BS-III &BS-IV VEHICLES <br />
                            EQV REF. NO. LF16238 <br />
                            F7A01500 <br />
                        </p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={oil2} alt="oil2" />
                    <div className="card-body">
                        <h6 className="card-title">OFM-UT-500</h6>
                        <p className="card-text">
                            OH Filter for All combine <br />
                            88 HP and AL Boss vehicle <br />
                            with 4CTI Engine EQV <br />
                            REF No. F7A05000 <br />
                            LF16305 <br /></p>
                    </div>
                </div><div className="card" style={{ width: '18rem' }}>
                    <img src={oil3} alt="oil3" />
                    <div className="card-body">
                        <h6 className="card-title">OFM-064</h6>
                        <p className="card-text">
                            OIL FILTER FORD <br />
                            EICHER GODREJ <br />
                            TRANSMISSION <br />
                            D10017600-A/ LF16303
                        </p>
                    </div>
                </div><div className="card" style={{ width: '18rem' }}>
                    <img src={oil4} alt="oil4" />
                    <div className="card-body">
                        <h6 className="card-title">OFM-100</h6>
                        <p className="card-text">
                            LEYLAND 370 <br />
                            EQV REF. NO. Lf16052, <br />
                            F 4034600 <br />
                        </p>
                    </div>
                </div><div className="card" style={{ width: '18rem' }}>
                    <img src={oil5} alt="oil5" />
                    <div className="card-body">
                        <h6 className="card-title">OFM-500 - HINO SMALL</h6>
                        <p className="card-text">
                            LEYLAND HINO OLD MODEL <br />
                            EQV REF. NO. Lf16057, <br />
                            P 3101540, F 4034200
                        </p>
                    </div>
                </div><div className="card" style={{ width: '18rem' }}>
                    <img src={oil6} alt="oil6" />
                    <div className="card-body">
                        <h6 className="card-title">OFM-516-HINO BIG</h6>
                        <p className="card-text">
                            LEYLAND HINO NEW MODEL <br />
                            EQV REF. NO. Lf16079, <br />
                            F 4001000, X 4000600
                        </p>
                    </div>
                </div><div className="card" style={{ width: '18rem' }}>
                    <img src={oil7} alt="oil7" />
                    <div className="card-body">
                        <h6 className="card-title">OFM-028</h6>
                        <p className="card-text">
                            TATA BS-III OIL FILTER <br />
                            EQV REF. NO. <br />
                            LF-9028,5285612, <br />
                            252718130145, P7A00013 <br />
                            IO 334686
                        </p>
                    </div>
                </div><div className="card" style={{ width: '18rem' }}>
                    <img src={oil8} alt="oil8" />
                    <div className="card-body">
                        <h6 className="card-title">OFM-015</h6>
                        <p className="card-text">
                            OIL FIILTER TATA PRIMA/ISBE ENGINE
                            EQV REF. NO. LF 16015, (57011899901),4897898
                        </p>
                    </div>
                </div><div className="card" style={{ width: '18rem' }}>
                    <img src={oil9} alt="oil9" />
                    <div className="card-body">
                        <h6 className="card-title">OFM-959</h6>
                        <p className="card-text">
                            AMW/T.C. LUBE ALL MODELS <br />
                            EQV REF. NO. LF 3959, <br />
                            278618139902
                        </p>
                    </div>
                </div><div className="card" style={{ width: '18rem' }}>
                    <img src={oil10} alt="oil11" />
                    <div className="card-body">
                        <h6 className="card-title">OFM-060</h6>
                        <p className="card-text">
                            TATA 1613 OIL FILTER <br />
                            EQV REF. NO. LF16060, <br />
                            (252518130139)
                        </p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={oil11} alt="oil11" />
                    <div className="card-body">
                        <h6 className="card-title">OFM-061</h6>
                        <p className="card-text">
                            LF16061 <br />
                            52718130132 <br />
                            Tata 7091909 Euro <br />I
                            1109 Ex Bs III</p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={oil12} alt="oil12" />
                    <div className="card-body">
                        <h6 className="card-title">OFM-104</h6>
                        <p className="card-text">
                            TATA 407 TURBO <br />
                            EQV REF. NO. LF16056, <br />
                            (253418130169)
                        </p>
                    </div>
                </div><div className="card" style={{ width: '18rem' }}>
                    <img src={oil13} alt="oil13" />
                    <div className="card-body">
                        <h6 className="card-title">OFM-966</h6>
                        <p className="card-text"> OIL FILTER FOR
                            TATA BS-IV 697 ENGINE <br />
                            EQV REF. NO. LF9667, <br />
                            252518130164 <br />
                            ID 321716</p>
                    </div>
                </div>
                {/* <div className="card" style={{ width: '18rem' }}>
                    <img src={oil14} alt="oil14" />
                    <div className="card-body">
                        <h6 className="card-title">OFM-303</h6>
                        <p className="card-text">
                            LUBE FILTER FOR TATA INDICA & <br />
                            ACE VEHICLES <br />
                            EQV REF. NO. LF 16080, 279018130101
                        </p>
                    </div>
                </div> */}
                <div className="card" style={{ width: '18rem' }}>
                    <img src={oil15} alt="oil15" />
                    <div className="card-body">
                        <h6 className="card-title">OFM-809</h6>
                        <p className="card-text">
                            EICHER JUMBO <br />
                            OIL FILTER <br />
                            EQV REF. NO. LF-16082, Id2032</p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={oil16} alt="oil16" />
                    <div className="card-body">
                        <h6 className="card-title">OFM-230</h6>
                        <p className="card-text"> EICHER CANTER <br />
                            OIL FILTER <br />
                            EQV REF. NO. LF-16069 <br />
                            ID 002922</p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={oil17} alt="oil17" />
                    <div className="card-body">
                        <h6 className="card-title">OFM-806</h6>
                        <p className="card-text"> P.NO. Lfl6341 10.95,11.10, <br />
                            E2Plus Bypass filter <br />
                            Ref. ID325856</p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={oil18} alt="oil18" />
                    <div className="card-body">
                        <h6 className="card-title">OFM-080</h6>
                        <p className="card-text">
                            TATA LONG LIFE 1210 <br />
                            EQV REF. NO. LF16051, <br />
                            2525181301124(DL)</p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={oil19} alt="oil19" />
                    <div className="card-body">
                        <h6 className="card-title">OFM-805</h6>
                        <p className="card-text">
                            SWARAJ MAZDA LUBE OIL <br />
                            BYPASS FILTER <br />
                            EQV REF. NO. PL 179900</p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={oil20} alt="oil20" />
                    <div className="card-body">
                        <h6 className="card-title">OFM-804</h6>
                        <p className="card-text">
                            EICHER CANTER LUBE OIL <br />
                            BYPASS FILTER <br />
                            LF 16090 <br />
                            ID 201024</p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={oil21} alt="oil21" />
                    <div className="card-body">
                        <h6 className="card-title">OFM-444</h6>
                        <p className="card-text"> S4 OIL FILTER <br />
                            ENGINE HYDRA <br />
                            EQV REF. NO. LF16092<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={oil22} alt="oil22" />
                    <div className="card-body">
                        <h6 className="card-title">OFM-90</h6>
                        <p className="card-text"> SL-90 OIL FILTER <br />
                            LF16075<br />
                            (06.436.01.0.00)<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={oil23} alt="oil23" />
                    <div className="card-body">
                        <h6 className="card-title">OFM-051</h6>
                        <p className="card-text"> Oil Filter Mahindra Peugoet <br />
                            LF 16065, 0303BC0071M<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={oil24} alt="oil24" />
                    <div className="card-body">
                        <h6 className="card-title">OFM-40</h6>
                        <p className="card-text">SL-90 OIL FILTER LF16075<br />
                            (06.436.01.0.00)<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={oil25} alt="oil25" />
                    <div className="card-body">
                        <h6 className="card-title">OFM-303</h6>
                        <p className="card-text">Lube Filter - Spin On - <br />
                            Tata Indica OIL FILTER CAV<br />
                            TATA SMALL ACE (ACE & INDICA), <br />
                            CHOTA HATHI, SUPER ACE, <br />
                            TATA ACELF-16080,<br />
                            2790 1813 0101<br /></p>

                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={oil26} alt="oil26" />
                    <div className="card-body">
                        <h6 className="card-title">OFM-22</h6>
                        <p className="card-text">EQV REF. NO. 304019000<br />
                            LF16072<br />
                            RB-22<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={oil27} alt="oil27" />
                    <div className="card-body">
                        <h6 className="card-title">OFM-163</h6>
                        <p className="card-text">OIL FILTER FOR<br />
                            CUMINS 5346895, ISBe 5.9 BS4<br />
                            ENGINE UMBRELLA<br />
                            EQV. Ref. No. LF 16306, 5346895,<br />
                            27801899 9994<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={oil28} alt="oil28" />
                    <div className="card-body">
                        <h6 className="card-title">OFM-110</h6>
                        <p className="card-text">OIL FILTER<br />
                            Element For DAIMLER OR BHARAT BENZ
                            1613,2523,3118,3116<br />
                            EQV. Ref No. E161H01,<br />
                            000 180 17 09 64,<br />
                            906 184 03 25, 000 180 17 09<br /></p>

                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={oil29} alt="oil29" />
                    <div className="card-body">
                        <h6 className="card-title">OFM-510</h6>
                        <p className="card-text" style={{ fontSize: '12px' }}>LUBE OIL FILTER<br />
                            FOR MAHINDRA 4.8/7.2L<br />
                            Engine IABOVE 16 TON Vehicle <br />
                            MAHINDRA, TRUCK,
                            MAHINDRA BLA 20<br />
                            Eqv.Ref.No
                            Lf16340, 5000935, 0303BAV00510N<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={oil30} alt="oil30" />
                    <div className="card-body">
                        <h6 className="card-title">OFM-053</h6>
                        <p className="card-text">LUBE OIL FILTER<br />
                            COMBO FOR PRO 1000<br />
                            SERIES E483 BS III IV<br />
                            EQV. REF. NO LF9053 Id337989<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={oil31} alt="oil31" />
                    <div className="card-body">
                        <h6 className="card-title">OFM-064</h6>
                        <p className="card-text">OIL FILTER <br />
                            FORD EICHER <br />
                            GODREJ TRANSMISSION <br />
                            D10017600-A/ LF16303<br /></p>
                    </div>
                </div>

            </div>
            <div className="note">
                <p>All the OEM numbers in this page are only for reference</p>
            </div>
        </div>
    )
}
