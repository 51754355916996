import React from 'react';
import './details.css'
import { Link } from 'react-router-dom';
import airprocess from './airprocess.jpg'
import 'slick-carousel/slick/slick.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the styles
import { Carousel } from 'react-responsive-carousel';
import 'slick-carousel/slick/slick-theme.css';
import air1 from './prodimg/air/air1.jpg'
import air2 from './prodimg/air/air2.jpg'
import air3 from './prodimg/air/air3.jpg'
import air4 from './prodimg/air/air4.jpg'
import air5 from './prodimg/air/air5.jpg'
import air6 from './prodimg/air/air6.jpg'
import air7 from './prodimg/air/air7.jpg'
import air8 from './prodimg/air/air8.jpg'
import air9 from './prodimg/air/air9.jpg'
import air10 from './prodimg/air/air10.jpg'
import air11 from './prodimg/air/air11.jpg'
import air12 from './prodimg/air/air12.jpg'
const Details = () => {

    return (
        <div className="filter-details">
            <h2>Air Filters</h2>

            <div className="information">
                <h3>Product Information</h3>
                <p>
                    Air filters are indispensable components in automotive engines, designed to safeguard the internal combustion process by screening out impurities from the air that enters the engine. By ensuring only clean and oxygen-rich air reaches the combustion chambers, air filters play a pivotal role in optimizing engine performance and longevity. Regular replacement of air filters is essential for sustaining fuel efficiency, preventing damage to engine components, and minimizing harmful emissions, making them a vital part of any vehicle's maintenance routine.
                </p>
                <h4>How does Air Filter work?</h4>
                <ol>
                    <li>Air Intake: The process begins when the vehicle draws in air from the surrounding environment for the combustion process.</li>
                    <li>Filtering Stage: The air passes through the air filter. The filter is composed of a fibrous material that traps particles like dust, dirt, pollen, and other contaminants present in the air.</li>
                    <li>Clean Air Passage: Once the air passes through the filter, the impurities are trapped in the filter material, allowing only clean air to proceed.</li>
                    <li>Air Combustion: In an internal combustion engine, the clean air is mixed with fuel in the combustion chamber, where it is ignited to generate power.</li>
                    <li>Protecting the Engine: The air filter plays a crucial role in preventing contaminants from entering the engine. Without a filter, these particles could cause wear and damage to engine components.</li>
                    <li>Sustaining Efficiency: A clean air filter ensures the engine receives a consistent and adequate supply of clean air, optimizing combustion and fuel efficiency.</li>
                    <li>Maintenance and Replacement:  Over time, the filter becomes clogged with trapped particles, reducing airflow. It's important to regularly inspect and replace the air filter according to the manufacturer's recommendations.</li>
                </ol>
                <img src={airprocess} alt="airprocess" className="process" />
            </div>

            <div className="specifications">
                <h4>Summary</h4>
                <ul>
                    <li>Air filters are crucial components in a vehicle's intake system. They remove impurities from the air before it enters the engine, ensuring a clean and efficient combustion process.</li>
                    <li>Air filters are designed to capture various airborne particles, including dust, pollen, dirt, and even tiny debris. This prevents these contaminants from entering the engine cylinders, which could lead to wear and reduced performance.</li>
                    <li>By preventing the entry of abrasive particles, air filters play a vital role in extending the lifespan of the engine. They help reduce friction and wear on internal components.</li>
                    <li>A clean air filter allows for better air flow, which is essential for the combustion process. This, in turn, improves fuel efficiency and ensures optimal power output.</li>
                    <li>It's crucial to inspect and replace air filters at recommended intervals to maintain engine performance. Neglecting this can lead to reduced fuel efficiency, decreased power, and potentially costly engine damage.</li>
                </ul>
            </div>

            <div className="image-card-container">
                <Carousel
                    autoPlay
                    interval={2000}
                    infiniteLoop
                    showThumbs={false}
                    showArrows={false}
                    centerMode
                    centerSlidePercentage={33.3}
                    emulateTouch
                    showStatus={false}
                    showIndicators={false} // Add this line to hide the sliding dots
                >
                    <div className='image-card'>
                        <img src={air1} alt="" />
                    </div>
                    <div className='image-card'>
                        <img src={air2} alt="" />
                    </div>
                    <div className='image-card'>
                        <img src={air3} alt="" />
                    </div>
                    <div className='image-card'>
                        <img src={air4} alt="" />
                    </div>
                    <div className='image-card'>
                        <img src={air5} alt="" />
                    </div>
                    <div className='image-card'>
                        <img src={air6} alt="" />
                    </div>
                    <div className='image-card'>
                        <img src={air7} alt="" />
                    </div>
                    <div className='image-card'>
                        <img src={air8} alt="" />
                    </div>
                    <div className='image-card'>
                        <img src={air9} alt="" />
                    </div>
                    <div className='image-card'>
                        <img src={air10} alt="" />
                    </div>
                    <div className='image-card'>
                        <img src={air11} alt="" />
                    </div>
                    <div className='image-card'>
                        <img src={air12} alt="" />
                    </div>
                </Carousel>
            </div>
            <Link to="/AirFilters">
                <h4 className='morefilters'>Click here to see all types of Air Filters</h4>
            </Link>
        </div>
    );
};

export default Details;
