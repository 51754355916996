import React from 'react'
import './App.css';
import fuel1 from './prodimg/fuel/fuel1.jpg'
import fuel2 from './prodimg/fuel/fuel2.jpg'
import fuel3 from './prodimg/fuel/fuel3.jpg'
import fuel4 from './prodimg/fuel/fuel4.jpg'
import fuel5 from './prodimg/fuel/fuel5.jpg'
import fuel6 from './prodimg/fuel/fuel6.jpg'
import fuel7 from './prodimg/fuel/fuel7.jpg'
import fuel8 from './prodimg/fuel/fuel8.jpg'
import fuel9 from './prodimg/fuel/fuel9.jpg'
import fuel10 from './prodimg/fuel/fuel10.jpg'
import fuel11 from './prodimg/fuel/fuel11.jpg'
import fuel12 from './prodimg/fuel/fuel12.jpg'
import fuel13 from './prodimg/fuel/fuel13.jpg'
import fuel14 from './prodimg/fuel/fuel14.jpg'
import fuel15 from './prodimg/fuel/fuel15.jpg'
import fuel16 from './prodimg/fuel/fuel16.jpg'
import fuel17 from './prodimg/fuel/fuel17.jpg'
import fuel18 from './prodimg/fuel/fuel18.jpg'
import fuel19 from './prodimg/fuel/fuel19.jpg'
import fuel20 from './prodimg/fuel/fuel20.jpg'
import fuel21 from './prodimg/fuel/fuel21.jpg'
import fuel22 from './prodimg/fuel/fuel22.jpg'
import fuel23 from './prodimg/fuel/fuel23.jpg'
import fuel24 from './prodimg/fuel/fuel24.jpg'
import fuel25 from './prodimg/fuel/fuel25.jpg'
import fuel26 from './prodimg/fuel/fuel26.jpg'

export default function FuelFilters() {
    return (
        <div>
            <div className="heading">
                <h3>Fuel Filter</h3>
            </div>
            <div className="card-container">
                <div className="card" style={{ width: '18rem' }}>
                    <img src={fuel1} alt="fuel1" />
                    <div className="card-body">
                        <h6 className="card-title">FFM-421</h6>
                        <p className="card-text">
                            FUEL FILTER FOR
                            TATA 3118 <br />
                            CUMMINS BS-III & BS-IV<br />
                            EQV. Ref. No.<br />
                            FF 5544,
                            5332229
                            278607989967<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={fuel2} alt="fuel2" />
                    <div className="card-body">
                        <h6 className="card-title">FFM-1000</h6>
                        <p className="card-text" style={{ textAlign: 'center' }}> FUEL FILTER FOR ASHOK LEYLAND<br />
                            Cb18 FIP CRS ENGINE<br />
                            EQV. Ref. No.50110283, <br />
                            FHJ01000,FF5552<br /></p>
                    </div>
                </div><div className="card" style={{ width: '18rem' }}>
                    <img src={fuel3} alt="fuel3" />
                    <div className="card-body">
                        <h6 className="card-title">STM - 851</h6>
                        <p className="card-text" style={{ textAlign: 'center' }}> LEYLAND TATA CUMMINS<br />
                            FUEL STRAINER<br />
                            (BOTH SIDE SMALL)<br />
                            EQV. Ref. No.F8824500,<br />
                            FF 5479 (2786099999951)<br /></p>

                    </div>
                </div><div className="card" style={{ width: '18rem' }}>
                    <img src={fuel4} alt="fuel4" />
                    <div className="card-body">
                        <h6 className="card-title">STM - 852</h6>
                        <p className="card-text" style={{ textAlign: 'center' }}> TATA FUEL STRAINER <br />
                            (ONE SIDE BIG/<br />
                            ONE SIDE SMALL) <br />
                            EQV. Ref.No. FF 5522, <br />
                            (2786099999920)<br /></p>
                    </div>
                </div><div className="card" style={{ width: '18rem' }}>
                    <img src={fuel5} alt="fuel5" />
                    <div className="card-body">
                        <h6 className="card-title">FFM-581</h6>
                        <p className="card-text"> FUEL FILTER CARTRIDGE FOR <br />
                            BOLERO ZLX M&M EQV. Ref. <br />
                            No.305DAB00581N, 9188-506<br /></p>
                    </div>
                </div><div className="card" style={{ width: '18rem' }}>
                    <img src={fuel6} alt="fuel6" />
                    <div className="card-body">
                        <h6 className="card-title">FFM-052</h6>
                        <p className="card-text" style={{ textAlign: 'center' }}> FUEL WATER SEPARATOR<br />
                            1612 TO 4018 ALL TC<br />
                            BS II & III Cross Ref. <br />
                            278609119904<br /></p>

                    </div>
                </div><div className="card" style={{ width: '18rem' }}>
                    <img src={fuel7} alt="fuel7" />
                    <div className="card-body">
                        <h6 className="card-title">FFM-460</h6>
                        <p className="card-text"> FUEL WATER Separator FS-ISBE 5.9<br />
                            BS-IV TATA Cartrigde 278609119910,<br />
                            Fs2011700<br /></p>
                    </div>
                </div><div className="card" style={{ width: '18rem' }}>
                    <img src={fuel8} alt="fuel8" />
                    <div className="card-body">
                        <h6 className="card-title">FFM-120</h6>
                        <p className="card-text"> FUEL FILTER<br />
                            Cartridge-DAIMLER OR BHARAT BENZ <br />
                            1613,2523,3118,3116 EQV. Ref. No. <br />
                            $6056 NE2 SOFIMA<br /></p>
                    </div>
                </div><div className="card" style={{ width: '18rem' }}>
                    <img src={fuel9} alt="fuel9" />
                    <div className="card-body">
                        <h6 className="card-title">FFM-888</h6>
                        <p className="card-text" style={{ textAlign: 'center' }}> Fuel filter Ultra 1518 <br />
                            Cartridge,  BS- VI OES<br />
                            Reference: 570909129904<br /></p>
                    </div>
                </div><div className="card" style={{ width: '18rem' }}>
                    <img src={fuel10} alt="fuel11" />
                    <div className="card-body">
                        <h6 className="card-title">FFM-296</h6>
                        <p className="card-text" style={{ textAlign: 'center' }}> FUEL FILTER<br />
                            (ACE & INDICA) FOR FILTER<br />
                            TATA ACE & INDICA DF-117<br />
                            Diesel Filter
                            CAV, 5535</p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={fuel11} alt="fuel11" />
                    <div className="card-body">
                        <h6 className="card-title">Kit - 606/ 607</h6>
                        <p className="card-text"> 1.1 Ltr Fuel Filter Paper + Cloth<br />
                            Primary & Secondary EQV. Ref. No.<br />
                            FF 5525/5526, 9457431003<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={fuel12} alt="fuel12" />
                    <div className="card-body">
                        <h6 className="card-title">Kit - 404 / 405</h6>
                        <p className="card-text"> 0.5 Ltr Fuel Filter Paper + Cloth<br />
                            Primary & Secondary EQV. Ref. No.<br />
                            FF 5523 / 5524<br /></p>
                    </div>
                </div><div className="card" style={{ width: '18rem' }}>
                    <img src={fuel13} alt="fuel13" />
                    <div className="card-body">
                        <h6 className="card-title">FFM-607</h6>
                        <p className="card-text" style={{ textAlign: 'center' }}> 1.1 Ltr Fuel Cloth/Primary <br />
                            Big -International,<br />
                            EQV. Ref. No.<br />
                            FF 5525, 9457431003<br /></p>
                    </div>
                </div><div className="card" style={{ width: '18rem' }}>
                    <img src={fuel14} alt="fuel14" />
                    <div className="card-body">
                        <h6 className="card-title">FFM-405</h6>
                        <p className="card-text" style={{ textAlign: 'center', fontSize: '15px' }}> 0.5 Ltr Fuel Cloth/Primary <br />
                            Small-International,<br />
                            EQV. Ref. No. FF 5523<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={fuel15} alt="fuel15" />
                    <div className="card-body">
                        <h6 className="card-title">FFM-606</h6>
                        <p className="card-text"> 1.1 Ltr FUEL PAPER <br />
                            SECONDARY BIG EQV. <br />
                            Ref. No. FF 5526<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={fuel16} alt="fuel16" />
                    <div className="card-body">
                        <h6 className="card-title">FFM-404</h6>
                        <p className="card-text" style={{ fontSize: '15px' }}> 0.5 Ltr Fuel Paper/<br />
                            Secondary Small-
                            International,
                            EQV. Ref. No.
                            FF 5524<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={fuel17} alt="fuel17" />
                    <div className="card-body">
                        <h6 className="card-title">FFM-609</h6>
                        <p className="card-text"> 1.1 Ltr FUEL COIL TYPE <br />
                            Big-ITL, EQV. Ref. No.<br />
                            FF 5534 ID 206984<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={fuel18} alt="fuel18" />
                    <div className="card-body">
                        <h6 className="card-title">FFM-407</h6>
                        <p className="card-text"> 0.5 Ltr Fuel Coil Type <br />
                            Small-ITL, EQV. Ref. No.<br />
                            FF 5533, ID 201628 <br />
                            9551037407, F4003300<br /></p>

                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={fuel19} alt="fuel19" />
                    <div className="card-body">
                        <h6 className="card-title">KIT-123</h6>
                        <p className="card-text"> TATA CUMMINS ENGINE SET OF 3 <br />
                            8261074 (FF1275 + LF3959 +<br />
                            FF5052)<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={fuel20} alt="fuel20" />
                    <div className="card-body">
                        <h6 className="card-title">KIT-102</h6>
                        <p className="card-text"> TATA CUMMINS ENGINE SET OF 2<br />
                            (FF5052+FF1275)<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={fuel21} alt="fuel21" />
                    <div className="card-body">
                        <h6 className="card-title">ASM-474</h6>
                        <p className="card-text"> Air Oil Separator FOR Cummins <br />
                            ISBe Engine (On Highway Apln.) -Indigenized,<br />
                            Eqv. Ref. No. AS2474, CUMMINS 4931691<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={fuel22} alt="fuel22" />
                    <div className="card-body">
                        <h6 className="card-title">FFM-380</h6>
                        <p className="card-text"> FUEL FILTER<br />
                            Cartridge-BHARAT BENZ 4928, <br />
                            Mercedes Benz - IV<br />
                            Oes Reference:0000901551/<br />
                            901251 P550632/FF5380/
                            Pu1046<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={fuel23} alt="fuel23" />
                    <div className="card-body">
                        <h6 className="card-title">FFM-888</h6>
                        <p className="card-text" style={{ fontSize: '15px' }}> Fuel filter Ultra 1518 Cartridge, <br />
                            BS-VI OEs Reference: 570909129904<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={fuel24} alt="fuel24" />
                    <div className="card-body">
                        <h6 className="card-title">FFM-889</h6>
                        <p className="card-text" style={{ fontSize: '15px' }}> Fuel filter Ultra 1518 Cartridge, <br />
                            BS- VI OES Reference: 570909129913<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={fuel25} alt="fuel25" />
                    <div className="card-body">
                        <h6 className="card-title">FFM-120</h6>
                        <p className="card-text"> Fuel filter cartridge -<br />
                            BHARAT BENZ 1613, 2523,
                            3118, 3116
                            OEs Reference:
                            DCA 4000920005
                            S6056 NE2 SOFIMA<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={fuel26} alt="fuel26" />
                    <div className="card-body">
                        <h6 className="card-title">FFM-540</h6>
                        <p className="card-text" style={{ fontSize: '12px' }}> Fuel filter for cummins QSN14<br />
                            Engine, CUMMINS B5.9<br />
                            LCCR 230 HP BSIII,<br />
                            Mahindra TruckFF-5540,<br />
                            OEM 4345001, CUMMINS5317717,<br />
                            0305DAV03030N,
                            278609999822<br /></p>

                    </div>
                </div>
            </div>
            <div className="note">
                <p>All the OEM numbers in this page are only for reference</p>
            </div>
        </div>
    )
}
