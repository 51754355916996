import React from 'react';
import { useRef } from 'react';
import emailjs from '@emailjs/browser';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faEnvelope, faPhone, faIdCard } from '@fortawesome/free-solid-svg-icons';

export default function Contact() {
    const form = useRef();


    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_f4xvxkl', 'template_he49fe6', form.current, 'h3UoB9wr1s2Vwsj4n')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset()
    };


    return (
        <>
            <div className="mapcontainer">
                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3773.3987390579427!2d72.815999575882!3d18.95798975566543!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7cf284b032953%3A0xe29505b10fd0d4d1!2sMICROGUARD%20FILTERS%20CORPORATION!5e0!3m2!1sen!2sin!4v1695549648756!5m2!1sen!2sin"
                    title="Google Map" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade">
                </iframe> */}
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3773.4040757461885!2d72.81571537588195!3d18.957753855672667!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7cf284b032953%3A0xe29505b10fd0d4d1!2sMICROGUARD%20FILTERS%20CORPORATION!5e0!3m2!1sen!2sin!4v1697609354261!5m2!1sen!2sin"
                    title="Google Map" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
                </iframe>
            </div>
            <div className="flex-container">
                <div className="contact-container">
                    <h2 className="contact-heading">Contact Us</h2>
                    <form ref={form} onSubmit={sendEmail}>
                        <div className="form-group">
                            <label htmlFor="name" className="form-label">Name:</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                required
                                className="form-input"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email" className="form-label">Email:</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                required
                                className="form-input"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="message" className="form-label">Message:</label>
                            <textarea
                                id="message"
                                name="message"
                                required
                                className="form-input"
                            ></textarea>
                        </div>
                        <button type="submit" className="submit-button">Submit</button>
                    </form>
                </div>
                <div className="address-container">
                    <h2 className="address-heading">Address</h2>
                    <div className="address-details">
                        <p><FontAwesomeIcon icon={faEnvelope} /> <strong>Email:</strong> microguardfilters@gmail.com</p>
                        <br />
                        <p><FontAwesomeIcon icon={faMapMarkerAlt} /> <strong>Address:</strong> 317,FLOOR GRD, PLOT NO -315 GIRIRAJ RESIDENCY, C.H.S VITTHAL BHAI PATEL ROAD, DO HATTI GALI, near AYODHYA RESTAURANT, Opera House, Girgaon, Mumbai, Maharashtra 400004</p>
                        <br />
                        <p><FontAwesomeIcon icon={faPhone} /> <strong>Mobile No:</strong> +91 9029648436</p>
                        <br />
                        <p><FontAwesomeIcon icon={faIdCard} /> <strong>GST Number:</strong> 27BPAPA1880C2ZG</p>
                    </div>
                </div>
            </div>
        </>
    )
}
