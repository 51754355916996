import React from 'react'
import './App.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AirFilters from './AirFilters';
import OilFilters from './OilFilters';
import WaterSeparator from './WaterSeparator';
import FuelFilters from './FuelFilters';
import Details from './Details';
import Details1 from './Details1';
import Details2 from './Details2';
import Details3 from './Details3';
// import SteeringFilters from './SteeringFilters';
// import IndustrialFilters from './IndustrialFilters';

export default function Product() {
    return (
        <>
            <div>
                {/* <Router> */}
                <div>
                    {/* <div><form className="d-flex" role="search">
                        <input className="form-control me-2" type="search" placeholder="Search Product" aria-label="Search" />
                        <button className="btn btn-outline-success" type="submit"><FontAwesomeIcon icon={faMagnifyingGlass} /></button>
                    </form>
                    </div> */}
                    <Switch>
                        <Route path="/AirFilters" >
                            <AirFilters />
                        </Route>
                        <Route path="/OilFilters">
                            <OilFilters />
                        </Route>
                        <Route path="/WaterSeparator">
                            <WaterSeparator />
                        </Route>
                        <Route path="/FuelFilters">
                            <FuelFilters />
                        </Route>
                        <Route path="/Details">
                            <Details />
                        </Route>
                        <Route path="/Details1">
                            <Details1 />
                        </Route>
                        <Route path="/Details2">
                            <Details2 />
                        </Route>
                        <Route path="/Details3">
                            <Details3 />
                        </Route>
                    </Switch>
                </div>
                {/* </Router> */}
            </div>
        </>
    )
}
