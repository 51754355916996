import React from 'react'
import './App.css';
import air1 from './prodimg/air/air1.jpg'
import air2 from './prodimg/air/air2.jpg'
import air3 from './prodimg/air/air3.jpg'
import air4 from './prodimg/air/air4.jpg'
import air5 from './prodimg/air/air5.jpg'
import air6 from './prodimg/air/air6.jpg'
import air7 from './prodimg/air/air7.jpg'
import air8 from './prodimg/air/air8.jpg'
import air9 from './prodimg/air/air9.jpg'
import air10 from './prodimg/air/air10.jpg'
import air11 from './prodimg/air/air11.jpg'
import air12 from './prodimg/air/air12.jpg'
import air13 from './prodimg/air/air13.jpg'
import air14 from './prodimg/air/air14.jpg'
import air15 from './prodimg/air/air15.jpg'
import air16 from './prodimg/air/air16.jpg'
import air17 from './prodimg/air/air17.jpg'
import air18 from './prodimg/air/air18.jpg'
import air19 from './prodimg/air/air19.jpg'
import air20 from './prodimg/air/air20.jpg'
import air21 from './prodimg/air/air21.jpg'
import air22 from './prodimg/air/air22.jpg'
import air23 from './prodimg/air/air23.jpg'
import air24 from './prodimg/air/air24.jpg'
import air25 from './prodimg/air/air25.jpg'
import air26 from './prodimg/air/air26.jpg'
import air27 from './prodimg/air/air27.jpg'
// import air28 from './prodimg/air/air28.jpg'
// import air29 from './prodimg/air/air29.jpg'
// import air30 from './prodimg/air/air30.jpg'
import air31 from './prodimg/air/air31.jpg'
import air32 from './prodimg/air/air32.jpg'
import air33 from './prodimg/air/air33.jpg'
import air34 from './prodimg/air/air34.jpg'
import air35 from './prodimg/air/air35.jpg'
import air36 from './prodimg/air/air36.jpg'
import air37 from './prodimg/air/air37.jpg'
import air38 from './prodimg/air/air38.jpg'
import air39 from './prodimg/air/air39.jpg'
import air40 from './prodimg/air/air40.jpg'
import air41 from './prodimg/air/air41.jpg'
import air42 from './prodimg/air/air42.jpg'
import air43 from './prodimg/air/air43.jpg'
import air44 from './prodimg/air/air44.jpg'
import air45 from './prodimg/air/air45.jpg'
import air46 from './prodimg/air/air46.jpg'
import air47 from './prodimg/air/air47.jpg'
import air48 from './prodimg/air/air48.jpg'



export default function AirFilters() {
    return (
        <div>
            <div className="heading">
                <h3>Air Filter</h3>
            </div>
            <div className="card-container">
                <div className="card" style={{ width: '18rem' }}>
                    <img src={air1} alt="air1" />
                    <div className="card-body">
                        <h6 className="card-title">AFM-485</h6>
                        <p className="card-text">
                            U TRUCK LEYLAND <br />
                            PRIMARY
                            EQV. Ref. No.
                            AF 26485
                            F7B01100
                            278609119904,6408301-0008<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={air2} alt="air2" />
                    <div className="card-body">
                        <h6 className="card-title">AFM-486</h6>
                        <p className="card-text">
                            U TRUCK LEYLAND <br />
                            SAFETY
                            EQV. Ref. No.
                            AF 26125, F7B01200
                            278609139935
                            6408301-009<br /></p>
                    </div>
                </div><div className="card" style={{ width: '18rem' }}>
                    <img src={air3} alt="air3" />
                    <div className="card-body">
                        <h6 className="card-title">AFM-669</h6>
                        <p className="card-text">
                            LEYLAND 2516,3516 4018,
                            TATA EX4018, T.C 2516,
                            TATA CUMMINS 3516
                            PRIMARY EQV. Ref. No.
                            AF 4669 AF 25938
                            278609 139908,
                            F8211200 ID 300068<br /></p>
                    </div>
                </div><div className="card" style={{ width: '18rem' }}>
                    <img src={air4} alt="air4" />
                    <div className="card-body">
                        <h6 className="card-title">AFM-670</h6>
                        <p className="card-text">
                            LEYLAND 2516,3516
                            4018, TATA EX4018, TC 2516,
                            TATA CUMMINS 3516 SAFETY
                            EQV. Ref. No.
                            AF 4670, AF 25948
                            278609139909
                            F8211300 Id300069.
                            P759104 KIT 8200068<br /></p>
                    </div>
                </div><div className="card" style={{ width: '18rem' }}>
                    <img src={air5} alt="air5" />
                    <div className="card-body">
                        <h6 className="card-title">AFM-671</h6>
                        <p className="card-text">
                            TATA / LEYLAND 2214 <br />
                            PRIMARY EQV. Ref. No. AF-947
                            (278609139910)
                            F8221200
                            KIT 885409041613
                            KIT 8200069
                            KIT P099625
                            F821120139908,
                            ID 3000680<br /></p>
                    </div>
                </div><div className="card" style={{ width: '18rem' }}>
                    <img src={air6} alt="air6" />
                    <div className="card-body">
                        <h6 className="card-title">AFM-672</h6>
                        <p className="card-text">
                            TATA/LEYLAND <br />
                            2214 SAFETY <br />
                            EQV. Ref. No. AF-963 <br />
                            (278609139911) F8221300<br /></p>

                    </div>
                </div><div className="card" style={{ width: '18rem' }}>
                    <img src={air7} alt="air7" />
                    <div className="card-body">
                        <h6 className="card-title">AFM-910</h6>
                        <p className="card-text">
                            TATA 1109, LEYLAND CARGO PRIMARY <br />
                            EQV. Ref. No.
                            AF 26002,
                            AF 26059
                            (252709130329)
                            X3962700<br /></p>

                    </div>
                </div><div className="card" style={{ width: '18rem' }}>
                    <img src={air8} alt="air8" />
                    <div className="card-body">
                        <h6 className="card-title">AFM-911</h6>
                        <p className="card-text">
                            TATA 1109, LEYLAND CARGO SAFETY
                            EQV. Ref. No.
                            AF 26006,
                            AF 26058,
                            (252709130330)
                            X3962800<br /></p>
                    </div>
                </div><div className="card" style={{ width: '18rem' }}>
                    <img src={air9} alt="air9" />
                    <div className="card-body">
                        <h6 className="card-title">AFM-117</h6>
                        <p className="card-text">
                            TATA 1613 <br />
                            TURBO PRIMARY <br />
                            EQV. Ref. No. AF-25884,
                            (252509140134)<br /></p>

                    </div>
                </div><div className="card" style={{ width: '18rem' }}>
                    <img src={air10} alt="air11" />
                    <div className="card-body">
                        <h6 className="card-title">AFM-118</h6>
                        <p className="card-text">
                            TATA 1613 <br />
                            TURBO SAFETY <br />
                            EQV. Ref. No. AF-25885,
                            (252509140133)<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={air11} alt="air11" />
                    <div className="card-body">
                        <h6 className="card-title">AFM-011</h6>
                        <p className="card-text">
                            PRIMARY <br /> MAHINDRA BLAZO
                            25, 31, 47,40/DAIMLER
                            EQV Ref. No. 6402DAB00011N
                            Mahindra<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={air12} alt="air12" />
                    <div className="card-body">
                        <h6 className="card-title">AFM-012</h6>
                        <p className="card-text">
                            SAFETY MAHINDRA BLAZO
                            25, 31,47, 40/DAIMLER
                            EQV Ref. No. 6402DAB00021N
                            Mahindra<br /></p>

                    </div>
                </div><div className="card" style={{ width: '18rem' }}>
                    <img src={air13} alt="air13" />
                    <div className="card-body">
                        <h6 className="card-title">AFM-305</h6>
                        <p className="card-text">
                            Tata 410 SFC
                            PRIMARY <br /> EQV.
                            Ref. No. AF 26075,
                            253409130305<br /></p>

                    </div>
                </div><div className="card" style={{ width: '18rem' }}>
                    <img src={air14} alt="air14" />
                    <div className="card-body">
                        <h6 className="card-title">AFM-306</h6>
                        <p className="card-text">
                            TATA 410 SFC SAFETY
                            EQV. Ref. No. AF 26074,
                            253409130306<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={air15} alt="air15" />
                    <div className="card-body">
                        <h6 className="card-title">AFM-248</h6>
                        <p className="card-text">
                            TATA 407 / SWARAJ MAZDA
                            EQV. Ref. No.
                            AF-4058252709130248
                            ID 202169,
                            KIT 5005190<br /></p>

                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={air16} alt="air16" />
                    <div className="card-body">
                        <h6 className="card-title">AFM-249</h6>
                        <p className="card-text">
                            TATA 407 / SWARAJ MAZDA
                            EQV. Ref. No.
                            AF-1842252709130249
                            ID 202170
                            KIT 5005190<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={air17} alt="air17" />
                    <div className="card-body">
                        <h6 className="card-title">AFM-700</h6>
                        <p className="card-text">
                            LEYLAND E-COMET1212
                            EQV. Ref. No.
                            AF 26070
                            F8845800<br /></p>

                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={air18} alt="air18" />
                    <div className="card-body">
                        <h6 className="card-title">AFM-701</h6>
                        <p className="card-text">
                            LEYLAND E-COMET1212
                            EQV. Ref. No.
                            AF 26069 F8845900<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={air19} alt="air19" />
                    <div className="card-body">
                        <h6 className="card-title">AFM-932</h6>
                        <p className="card-text">
                            TATA 4923 LPS (PRI)
                            Novas 8200354
                            EQV. Ref. No.
                            AF 26063
                            8200354
                            278609139931N<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={air20} alt="air20" />
                    <div className="card-body">
                        <h6 className="card-title">AFM-933</h6>
                        <p className="card-text">
                            TATA 4923 LPS(SEC)
                            Novas 8200344 EQV. Ref. No.
                            AF 26056 8200344
                            219309130202<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={air21} alt="air21" />
                    <div className="card-body">
                        <h6 className="card-title">AFM-6077</h6>
                        <p className="card-text">
                            PRIMARY <br />
                            AIR FILTER ELEMENT <br />
                            FOR MAN TRUCK / <br />
                            AMW 2518 BS-III
                            EQV. Ref. No.
                            AF 26090,
                            (570118999901) 3361<br /></p>

                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={air22} alt="air22" />
                    <div className="card-body">
                        <h6 className="card-title">AFM-6078</h6>
                        <p className="card-text">
                            SECONDARY AIR FILTER ELEMENT <br />
                            FOR MAN TRUCK / <br />
                            AMW 2518 BS-III EQV. Ref. No.
                            AF 26089, (2880047704904)
                            00044336100045<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={air23} alt="air23" />
                    <div className="card-body">
                        <h6 className="card-title">AFM-092</h6>
                        <p className="card-text">
                            LEYLAND DOST <br />
                            EQV. Ref. No.
                            AF 26092 165462VAIA<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={air24} alt="air24" />
                    <div className="card-body">
                        <h6 className="card-title">AFM-300</h6>
                        <p className="card-text">
                            AIR FILTER BOLERO,
                            SCORPIO.BSZ TURBO 6 GIX,
                            EQV Ref. No.FG Af26097
                            KFA021-7056<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={air25} alt="air25" />
                    <div className="card-body">
                        <h6 className="card-title">AFM-974</h6>
                        <p className="card-text">
                            TATA PRIMA 4040S PRIMARY <br />
                            EQV Ref. No. MANN C281275,
                            TATA 286309139928N AF-27974<br /></p>

                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={air26} alt="air26" />
                    <div className="card-body">
                        <h6 className="card-title">AFM-975</h6>
                        <p className="card-text">
                            SAFETY MAHINDRA BLAZO
                            25, 31,47, 40/ DAIMLER
                            EQV Ref. No. 6402DAB00021N
                            Mahindra<br /></p>

                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={air27} alt="air27" />
                    <div className="card-body">
                        <h6 className="card-title">AFM-250</h6>
                        <p className="card-text">
                            Tata Spacio Gold Plus,
                            SUMO VICTA (SPACIOGOLD PLUS)
                            BS III Eqv. Ref. No.
                            2534 0913 0262,
                            (8200315), GSK -05529,
                            8200667, 8200622,
                            253409130368, 9095787001,
                            AF26071<br /></p>

                    </div>
                </div>
                {/* <div className="card" style={{ width: '18rem' }}>
                    <img src={air28} alt="air28" />
                    <div className="card-body">
                        <h6 className="card-title">AFM-945</h6>
                        <p className="card-text">
                            U TRUCK LEYLAND <br />
                            PRIMARY <br />
                            EQV. Ref. No.
                            AF 26485,
                            F7B01100,
                            278609119904, 6408301-0008,
                            278609139936,
                            KIT 885421012518
                            KIT 5005194 <br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={air28} alt="air28" />
                    <div className="card-body">
                        <h6 className="card-title">AFM-945</h6>
                        <p className="card-text">
                            U TRUCK LEYLAND <br />
                            PRIMARY <br />
                            EQV. Ref. No.
                            AF 26485,
                            F7B01100,
                            278609119904, 6408301-0008,
                            278609139936,
                            KIT 885421012518
                            KIT 5005194 <br /></p>
                    </div>
                </div> */}
                <div className="card" style={{ width: '18rem' }}>
                    <img src={air45} alt="air45" />
                    <div className="card-body">
                        <h6 className="card-title">AFM-483</h6>
                        <p className="card-text" style={{ fontSize: '12px' }}>
                            AIR FILTER PRIMARY<br />
                            FOR ASHOK LEYLAND<br />
                            BOSS, BOSSTATA 1100 SERIES,
                            TATA 1109/909
                            ULTRA NEW MODEL272709130143,
                            252709140258, 8200778,
                            AF26483U/F7B02700<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={air46} alt="air46" />
                    <div className="card-body">
                        <h6 className="card-title">AFM-484</h6>
                        <p className="card-text" style={{ fontSize: '12px' }}>
                            AIR FILTER SAFETY FOR<br />
                            ASHOK LEYLAND BOSS, <br />
                            TATA 1100 SERIES,
                            TATA 1109/909
                            ULTRA NEW MODEL272709130144, <br />
                            252709140259, 8200777, A<br />
                            F25963U/F7B02800<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={air31} alt="air31" />
                    <div className="card-body">
                        <h6 className="card-title">AFM-5566</h6>
                        <p className="card-text">
                            FILTER SET FOR EICHER PRO
                            (BIG) 5016, PRO 5025,
                            PRO 5031PRO 5035,PRO
                            5040,PRO 5019,PRO
                            5028,PRO 6042,6028,
                            6035 Id316950 <br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={air32} alt="air32" />
                    <div className="card-body">
                        <h6 className="card-title">AFM-5567</h6>
                        <p className="card-text">
                            FILTER SET FOR EICHER PRO
                            (BIG) 5016, PRO 5025,
                            PRO 5031PRO 5035,PRO
                            5040,PRO 5019,PRO
                            5028,PRO 6042,6028,
                            6035 Id316950 <br /></p>

                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={air33} alt="air33" />
                    <div className="card-body">
                        <h6 className="card-title">AFM-2233</h6>
                        <p className="card-text">
                            FILTER SET FOR EICHER PRO
                            (BIG) 5016, PRO 5025,
                            PRO 5031PRO 5035,PRO
                            5040,PRO 5019,PRO
                            5028,PRO 6042,6028,
                            6035 Id316950 <br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={air34} alt="air34" />
                    <div className="card-body">
                        <h6 className="card-title">AFM-2234</h6>
                        <p className="card-text">
                            FOR EICHER PRO
                            (SECONDARY AIR FILTER)
                            1049,1050,1080,1095XP,
                            1110, 1112, 1114, 1075,
                            3015, 3016, 30086, 3012,
                            3014 (SMALL) Id362219<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={air35} alt="air35" />
                    <div className="card-body">
                        <h6 className="card-title">AFM-025</h6>
                        <p className="card-text">
                            (PRIMARY AIR FILTER)<br />
                            BB LCV 912,
                            1214, 712
                            OEs Reference:
                            DICV A 400 528 0106
                            DICV A400 528 00 06<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={air36} alt="air36" />
                    <div className="card-body">
                        <h6 className="card-title">AFM-026</h6>
                        <p className="card-text">
                            (SECONDARY AIR FILTER) <br />
                            BB LCV 912, 1214, 712
                            OEs Reference:
                            DICV A 400 528 0106
                            DICV A400 528 00 06<br /></p>

                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={air37} alt="air37" />
                    <div className="card-body">
                        <h6 className="card-title">AFM-104</h6>
                        <p className="card-text">
                            (PRIMARY AIR FILTER)<br />
                            E-2 Plus Eicher
                            OEs Reference:Id303248
                            MODEL 5010377<br /></p>

                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={air38} alt="air38" />
                    <div className="card-body">
                        <h6 className="card-title">AFM-105</h6>
                        <p className="card-text">
                            (SECONDARY AIR FILTER)
                            E-2 Plus Eicher OEs Reference:
                            Id303249
                            MODEL 5010377<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={air39} alt="air39" />
                    <div className="card-body">
                        <h6 className="card-title">AFM-928</h6>
                        <p className="card-text">
                            250KVA 6C <br />
                            TA8.3 ENGINE PRIMARY<br />
                            EQV.REF.NO.
                            AF 26061, AF25877,<br />
                            Af26027, 286609139901N,<br />
                            F8283900<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={air40} alt="air40" />
                    <div className="card-body">
                        <h6 className="card-title">AFM-929</h6>
                        <p className="card-text" style={{ fontSize: '12px' }}>
                            AMWTRUCK,<br />
                            250KVA 6C TA8.3 <br />
                            ENGINE SAFETY
                            EQV.REF.NO. AF 26060,
                            AF 26028, AF 25878,<br />
                            AF 25924, 286609139902N
                            F82 84000<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={air41} alt="air41" />
                    <div className="card-body">
                        <h6 className="card-title">AFM-481</h6>
                        <p className="card-text" style={{ fontSize: '12px' }}>
                            AIR FILTER PRIMARY <br />
                            FOR ASHOK LEYLAND GURU, <br />
                            1000 series OptAir of Koel/
                            Cummins Engine 04.760.01.0.00,
                            2890655, AF26481, 5000144,
                            10005607 / 8200819 / 8200842/
                            FG800700<br /></p>

                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={air42} alt="air42" />
                    <div className="card-body">
                        <h6 className="card-title">AFM-482</h6>
                        <p className="card-text" style={{ fontSize: '12px' }}>
                            AIR FILTER SAFETY <br />
                            FOR ASHOK LEYLAND GURU, <br />
                            1000 series Opt Air of Koel/ <br />
                            Cummins Engine
                            OE NO04.760.02.0.00,
                            2890656, AF26121, 5000143,
                            / 10005606 / 8200818 /
                            8200841 /B4847806<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={air43} alt="air43" />
                    <div className="card-body">
                        <h6 className="card-title">AFM-797</h6>
                        <p className="card-text">
                            AIR FILTER-095<br />
                            AF 26095 (OUTER)<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={air44} alt="air44" />
                    <div className="card-body">
                        <h6 className="card-title">AFM-798</h6>
                        <p className="card-text">
                            AIR FILTER-095<br />
                            AF 26095/AF-26096<br />
                            002 5003914<br />
                            /5003915/8200796/8200797<br />
                            ENGINE 1200 SERIES SHORTER<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={air47} alt="air47" />
                    <div className="card-body">
                        <h6 className="card-title">AFM-300</h6>
                        <p className="card-text">
                            BOLERO/SCORPIO BS2 <br />
                            Turbo 6GLX KFA02-1-7056, <br />
                            AF 26097<br /></p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <img src={air48} alt="air48" />
                    <div className="card-body">
                        <h6 className="card-title">AFM-600</h6>
                        <p className="card-text">
                            Tata Super Ace <br />
                            286909139904<br />
                            AF 26098<br /></p>
                    </div>
                </div>
            </div>
            <div className="note">
                <p>All the OEM numbers in this page are only for reference</p>
            </div>
        </div>
    )
}
