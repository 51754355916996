import React, { useState } from 'react';
import logo2 from './logo2.png';
// import logo from './logo.png';
import logo3 from './logo3.jpg';
import './navbar.css';
import { Link } from 'react-router-dom';
import { NavDropdown } from 'react-bootstrap';
// import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Navbar() {

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const closeSidebar = () => {
        if (isSidebarOpen) {
            setIsSidebarOpen(false);
        }
    }

    return (
        <div>
            <nav className="navbar navbar-expand-lg">
                <div className="logo-container">
                    <img src={logo3} alt="Logo3" className="logo" />
                    <img src={logo2} alt="Logo2" className="logo2" />
                    {/* <img src={logo2} alt="Logo3" className="logo2" /> */}
                </div>
                <button
                    className="navbar-toggler"
                    type="button"
                    onClick={toggleSidebar}
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link className="nav-link" to="/" onClick={closeSidebar}>
                                <span className='text'>Home</span>
                            </Link>
                        </li>

                        <NavDropdown title={<span className="text">Products</span>} id="basic-nav-dropdown">
                            <NavDropdown.Item as={Link} to="/AirFilters" className="nav-link dropdown-item">Air Filter</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item as={Link} to="/OilFilters" className="nav-link dropdown-item">Oil Filter</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item as={Link} to="/WaterSeparator" className="nav-link dropdown-item">Water Separator</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item as={Link} to="/FuelFilters" className="nav-link dropdown-item">Fuel Filters</NavDropdown.Item>
                            {/* <NavDropdown.Divider />
                            <NavDropdown.Item as={Link} to="/SteeringFilters" className="nav-link dropdown-item">Steering Filter</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item as={Link} to="/IndustrialFilters" className="nav-link dropdown-item">Industrial Filters</NavDropdown.Item> */}
                        </NavDropdown>

                        <li className="nav-item">
                            <Link className="nav-link" to="/About" onClick={closeSidebar}>
                                <span className='text'>About Us</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/Contact" onClick={closeSidebar}>
                                <span className='text'>Contact Us</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>

            {/* Sidebar */}
            <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
                <ul>
                    <hr />
                    <li className='toggletext'>
                        <Link to="/" onClick={closeSidebar}>
                            <span>Home</span>
                        </Link>
                    </li>
                    <hr />
                    <li className="dropdown">
                        <span>Products</span>
                        <div className="dropdown-menu" aria-labelledby="specialityDropdown">
                            <Link to="/AirFilters" onClick={closeSidebar} className="dropdown-item">Air Filters</Link>
                            <hr />
                            <Link to="/OilFilters" onClick={closeSidebar} className="dropdown-item">Oil Filter</Link>
                            <hr />
                            <Link to="/WaterSeparator" onClick={closeSidebar} className="dropdown-item">Water Separator</Link>
                            <hr />
                            <Link to="/FuelFilters" onClick={closeSidebar} className="dropdown-item">Fuel Filters</Link>
                            {/* <hr />
                                <Link to="/SteeringFilters" onClick={closeSidebar} className="dropdown-item">Steering Filter</Link>
                                <hr />
                                <Link to="/IndustrialFilters" onClick={closeSidebar} className="dropdown-item">Industrial Filters</Link> */}
                        </div>
                    </li>

                    <hr />
                    <li className='toggletext'>
                        <Link to="/About" onClick={closeSidebar}>
                            <span>About Us</span>
                        </Link>
                    </li>
                    <hr />
                    <li className='toggletext'>
                        <Link to="/Contact" onClick={closeSidebar}>
                            <span>Contact Us</span>
                        </Link>
                    </li>
                    <hr />
                </ul>
            </div>
        </div>
    );
}
