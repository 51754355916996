import React from 'react'
import Slide from './Slide'
import Featured from './Featured';
import Elements from './Elements';
import ProductDetails from './ProductDetails';
import './details.css'
import Extra from './Extra';

export default function Home() {
    return (
        <div>
            <Slide />
            <Featured />
            <ProductDetails />
            <Elements />
            <div className="mgfcontainer">
                <h2>"MicroGuard Filters Corporation is a pioneering force in the filtration industry, dedicated exclusively to crafting exceptional filters for a diverse range of vehicles. With unwavering commitment to quality and innovation, we proudly introduce our own distinguished brand, setting us apart in the market. Our filters are meticulously designed and precision-engineered to meet the highest standards, ensuring optimal performance and protection for engines and critical machinery."</h2>
            </div>
            {/* <Bestseller /> */}
            {/* <div className='custom'>We also make custom designed filters as well
                <br />
                Do visit our products section for more types of filters
            </div> */}
            <Extra />
        </div>
    )
}
