import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Details from './Details';
import Details1 from './Details1';
import Details2 from './Details2';
import Details3 from './Details3';

export default function Featured() {


    return (
        <>
            <div className="main">
                <div className="container1">
                    <div className="rectangle"><h4>FEATURED PRODUCTS</h4></div>
                </div>
            </div>
            <Router>
                <Switch>
                    <Route path="/Details">
                        <Details />
                    </Route>
                    <Route path="/Details1">
                        <Details1 />
                    </Route>
                    <Route path="/Details2">
                        <Details2 />
                    </Route>
                    <Route path="/Details3">
                        <Details3 />
                    </Route>
                </Switch>
            </Router>
        </>
    )
}
