import React from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import './App.css';
import './App.css'
import Navbar from "./Navbar"
import Home from './Home';
import Footer from './Footer';
import Contact from './Contact';
import Product from './Product';
import About from './About'
import whatsapp from './whatsapp.png'


export default function Main() {
    const handleClick = () => {
        // Redirect to a website
        window.location.href = 'https://wa.me/919029648436';
    };
    return (
        <div>
            <Router>
                <div>
                    <Navbar />
                    <div className="whatsapp" onClick={handleClick}>
                        <img src={whatsapp} alt="whatsapp" className="whatsapp-img" />
                    </div>
                    <Switch>
                        <Route exact path="/">
                            <Home />
                        </Route>
                        <Route path="/About">
                            <About />
                        </Route>
                        <Route path="/Contact">
                            <Contact />
                        </Route>
                        <Product />
                    </Switch>
                    <Footer />
                </div>
            </Router>
        </div>
    )
}
