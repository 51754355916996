import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './App.css';
import airfilter1 from './airfilter1.jpg'
import airfilter2 from './airfilter2.jpg'
import airfilter3 from './airfilter3.jpg'
import airfilter4 from './airfilter4.jpg'
import oilfilter1 from './oilfilter1.jpg'
import oilfilter2 from './oilfilter2.jpg'
import oilfilter3 from './oilfilter3.jpg'
import oilfilter4 from './oilfilter4.jpg'
import watersep1 from './watersep1.jpg'
import watersep2 from './watersep2.jpg'
import watersep3 from './watersep3.jpg'
import watersep4 from './watersep4.jpg'
import fuelfilter1 from './fuelfilter1.jpg'
import fuelfilter2 from './fuelfilter2.jpg'
import fuelfilter3 from './fuelfilter3.jpg'
import fuelfilter4 from './fuelfilter4.jpg'



export default function ProductDetails() {
    const images = [airfilter1, airfilter2, airfilter3, airfilter4];
    const images1 = [oilfilter1, oilfilter2, oilfilter3, oilfilter4];
    const images2 = [watersep1, watersep2, watersep3, watersep4];
    const images3 = [fuelfilter1, fuelfilter2, fuelfilter3, fuelfilter4]
    const [currentImage, setCurrentImage] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImage(prevImage => (prevImage + 1) % images.length);
        }, 3000); // Change slide every 3 seconds (adjust as needed)

        return () => clearInterval(interval);
    }, [images.length], [images1.length], [images2.length], [images3.length]);
    return (
        <div>
            <div className="wrapper">
                {images.map((src, index) => (
                    <div className={`single-item ${index === currentImage ? 'active' : ''}`} key={index}>
                        <img src={src} className='imgc' alt="" />
                        <div className="content">
                            <Link to="/Details" style={{ textDecoration: 'none', color: 'inherit' }}>
                                <p style={{ textDecoration: 'none', color: 'inherit' }}>Product Details</p>
                            </Link>
                        </div>
                        <div className='square'><p>Air Filter</p></div>
                    </div>
                ))}
                {images1.map((src, index) => (
                    <div className={`single-item ${index === currentImage ? 'active' : ''}`} key={index}>
                        <img src={src} className='imgc' alt="" />
                        <div className="content">
                            <Link to="/Details1" style={{ textDecoration: 'none', color: 'inherit' }}>
                                <p style={{ textDecoration: 'none', color: 'inherit' }}>Product Details</p>
                            </Link>
                        </div>
                        <div className='square'><p>Oil Filter</p></div>
                    </div>
                ))}
                {images2.map((src, index) => (
                    <div className={`single-item ${index === currentImage ? 'active' : ''}`} key={index}>
                        <img src={src} className='imgc' alt="" />
                        <div className="content">
                            <Link to="/Details2" style={{ textDecoration: 'none', color: 'inherit' }}>
                                <p style={{ textDecoration: 'none', color: 'inherit' }}>Product Details</p>
                            </Link>
                        </div>
                        <div className='square'><p>Water Separator</p></div>
                    </div>
                ))}
                {images3.map((src, index) => (
                    <div className={`single-item ${index === currentImage ? 'active' : ''}`} key={index}>
                        <img src={src} className='imgc' alt="" />
                        <div className="content">
                            <Link to="/Details3" style={{ textDecoration: 'none', color: 'inherit' }}>
                                <p style={{ textDecoration: 'none', color: 'inherit' }}>Product Details</p>
                            </Link>
                        </div>
                        <div className='square'><p>Fuel Filter</p></div>
                    </div>
                ))}
            </div>
            <div className="slider-controls">
                <div className="slider-control" onClick={() => setCurrentImage(prevImage => (prevImage > 0 ? prevImage - 1 : images.length - 1))}>
                    &lt;
                </div>
                <div className="slider-control" onClick={() => setCurrentImage(prevImage => (prevImage + 1) % images.length)}>
                    &gt;
                </div>
            </div>
        </div>
    )
}
