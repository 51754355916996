import React from 'react'
import './details.css'
import { Link } from 'react-router-dom';
import oilprocess from './oilprocess.jpg'
import 'slick-carousel/slick/slick.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the styles
import { Carousel } from 'react-responsive-carousel';
import 'slick-carousel/slick/slick-theme.css';
import oil1 from './prodimg/oil/oil1.jpg'
import oil2 from './prodimg/oil/oil2.jpg'
import oil3 from './prodimg/oil/oil3.jpg'
import oil4 from './prodimg/oil/oil4.jpg'
import oil5 from './prodimg/oil/oil5.jpg'
import oil6 from './prodimg/oil/oil6.jpg'
import oil7 from './prodimg/oil/oil7.jpg'
import oil8 from './prodimg/oil/oil8.jpg'
import oil9 from './prodimg/oil/oil9.jpg'
import oil10 from './prodimg/oil/oil10.jpg'
import oil11 from './prodimg/oil/oil11.jpg'
import oil12 from './prodimg/oil/oil12.jpg'

export default function Details1() {
    return (
        <div className="filter-details">
            <h2>Oil Filters</h2>

            <div className="information">
                <h3>Product Information</h3>
                <p>
                    An oil filter is a filter designed to remove contaminants from engine oil, transmission oil, lubricating oil, or hydraulic oil. Their chief use is in internal-combustion engines for motor vehicles (both on- and off-road ), powered aircraft, railway locomotives, ships and boats, and static engines such as generators and pumps. Other vehicle hydraulic systems, such as those in automatic transmissions and power steering, are often equipped with an oil filter. Gas turbine engines, such as those on jet aircraft, also require the use of oil filters. Oil filters are used in many different types of hydraulic machinery. The oil industry itself employs filters for oil production, oil pumping, and oil recycling. Modern engine oil filters tend to be "full-flow" (inline) or "bypass".
                </p>
                <h4>How does Oil Filter work?</h4>
                <p>The outside of the filter is a metal can with a sealing gasket that allows it to be tightly held against the engine’s mating surface. The base plate of the can holds the gasket and is perforated with holes around the area just inside the gasket. A central hole is threaded to mate with the oil filter assembly on the engine block. Inside the can is the filter material, most frequently made from synthetic fiber. The engine’s oil pump moves the oil directly to the filter, where it enters from the holes in the perimeter of the base plate. The dirty oil is passed (pushed under pressure) through the filter media and back through the central hole, where it re-enters the engine.</p>
                <img src={oilprocess} alt="oilprocess" className="process" />
            </div>

            <div className="specifications">
                <h4>Summary</h4>
                <ul>
                    <li>Oil filters are essential components in an engine's lubrication system. They function to remove contaminants such as dirt, metal particles, and sludge from the engine oil. This helps maintain the cleanliness and effectiveness of the oil.</li>
                    <li>By removing impurities from the oil, oil filters play a crucial role in preventing wear and tear on engine components. This prolongs the life of the engine and ensures it operates efficiently.</li>
                    <li>Contaminated oil can become thicker and less effective at lubricating engine parts. Oil filters help maintain the proper viscosity of the oil, ensuring it flows smoothly and consistently throughout the engine.</li>
                    <li>Clean oil is vital for optimal engine performance. Oil filters contribute to smoother operation, better fuel efficiency, and reduced emissions by providing clean, well-lubricated engine components.</li>
                    <li>Effective oil filtration allows for longer intervals between oil changes. High-quality oil filters can capture a significant amount of contaminants, providing extended protection for the engine and potentially reducing maintenance costs.</li>
                    {/* Add more specifications */}
                </ul>
            </div>

            <div className="image-card-container">
                <Carousel
                    autoPlay
                    interval={2000}
                    infiniteLoop
                    showThumbs={false}
                    showArrows={false}
                    centerMode
                    centerSlidePercentage={33.3}
                    emulateTouch
                    showStatus={false}
                    showIndicators={false} // Add this line to hide the sliding dots
                >
                    <div className='image-card'>
                        <img src={oil1} alt="" />
                    </div>
                    <div className='image-card'>
                        <img src={oil2} alt="" />
                    </div>
                    <div className='image-card'>
                        <img src={oil3} alt="" />
                    </div>
                    <div className='image-card'>
                        <img src={oil4} alt="" />
                    </div>
                    <div className='image-card'>
                        <img src={oil5} alt="" />
                    </div>
                    <div className='image-card'>
                        <img src={oil6} alt="" />
                    </div>
                    <div className='image-card'>
                        <img src={oil7} alt="" />
                    </div>
                    <div className='image-card'>
                        <img src={oil8} alt="" />
                    </div>
                    <div className='image-card'>
                        <img src={oil9} alt="" />
                    </div>
                    <div className='image-card'>
                        <img src={oil10} alt="" />
                    </div>
                    <div className='image-card'>
                        <img src={oil11} alt="" />
                    </div>
                    <div className='image-card'>
                        <img src={oil12} alt="" />
                    </div>
                </Carousel>
            </div>
            <Link to="/OilFilters">
                <h4 className='morefilters'>Click here to see all types of Oil Filters</h4>
            </Link>
        </div>
    )
}
