import React from 'react'
import Poster from './Poster'

export default function Extra() {
    return (
        <div>
            <Poster />
        </div>
    )
}
