import React from 'react';
// import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import './App.css';
import './components/App.css'
// import Navbar from "./components/Navbar"
// import Home from './components/Home';
// import Footer from './components/Footer';
// import Contact from './components/Contact';
// import About from './components/About'
import Main from './components/Main';

function App() {
  return (
    <Main />
  );
}

export default App;

