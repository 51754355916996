import React from 'react'
import 'slick-carousel/slick/slick.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the styles
import { Carousel } from 'react-responsive-carousel';
import 'slick-carousel/slick/slick-theme.css';
import poster1 from './prodimg/poster/poster1.jpg'
import poster2 from './prodimg/poster/poster2.jpg'
import poster3 from './prodimg/poster/poster3.jpg'
import poster4 from './prodimg/poster/poster4.jpg'
import poster5 from './prodimg/poster/poster5.jpg'
import poster6 from './prodimg/poster/poster6.jpg'
import poster7 from './prodimg/poster/poster7.jpg'
import poster8 from './prodimg/poster/poster8.jpg'
import poster9 from './prodimg/poster/poster9.jpg'
import poster10 from './prodimg/poster/poster10.jpg'
import poster11 from './prodimg/poster/poster11.jpg'
import './poster.css'

export default function Poster() {
    return (
        <div>
            <div className="poster-heading">
                <h2>See More About Us</h2>
            </div>
            <div className="poster-card-container">
                <Carousel
                    autoPlay
                    interval={2000}
                    infiniteLoop
                    showThumbs={false}
                    showArrows={false}
                    centerMode
                    centerSlidePercentage={20}
                    emulateTouch
                    showStatus={false}
                    showIndicators={false} // Add this line to hide the sliding dots
                >
                    <div className='poster-card'>
                        <img src={poster1} alt="" />
                    </div>
                    <div className='poster-card'>
                        <img src={poster2} alt="" />
                    </div>
                    <div className='poster-card'>
                        <img src={poster3} alt="" />
                    </div>
                    <div className='poster-card'>
                        <img src={poster4} alt="" />
                    </div>
                    <div className='poster-card'>
                        <img src={poster5} alt="" />
                    </div>
                    <div className='poster-card'>
                        <img src={poster6} alt="" />
                    </div>
                    <div className='poster-card'>
                        <img src={poster7} alt="" />
                    </div>
                    <div className='poster-card'>
                        <img src={poster8} alt="" />
                    </div>
                    <div className='poster-card'>
                        <img src={poster9} alt="" />
                    </div>
                    <div className='poster-card'>
                        <img src={poster10} alt="" />
                    </div>
                    <div className='poster-card'>
                        <img src={poster11} alt="" />
                    </div>
                </Carousel>
            </div>
        </div>
    )
}
