import React, { useState, useEffect } from 'react';
import './App.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faUserFriends, faCog } from '@fortawesome/free-solid-svg-icons';

export const YearCounter = () => {
    const [year, setYear] = useState(1000);
    const targetYear = 1200;

    useEffect(() => {
        const interval = setInterval(() => {
            if (year < targetYear) {
                setYear(year + 1);
            } else {
                clearInterval(interval);
            }
        }, 10);

        return () => clearInterval(interval);
    }, [year, targetYear]);

    return (
        <div className="counter">
            <div className="fticon"><FontAwesomeIcon icon={faFilter} size="2x" /></div>
            <h2>Filters</h2>
            <h1 className='counterval'>{year}+</h1>
        </div>
    );
};

export const CustomersCounter = () => {
    const [count, setCount] = useState(0);
    const targetCount = 2520; // 25 lakhs

    useEffect(() => {
        const interval = setInterval(() => {
            if (count < targetCount) {
                setCount(count + 70);
            } else {
                clearInterval(interval);
            }
        }, 40);

        return () => clearInterval(interval);
    }, [count, targetCount]);

    return (
        <div className="counter">
            <div className="fticon"><FontAwesomeIcon icon={faUserFriends} size="2x" /></div>
            <h2>Primary Customers</h2>
            <h1 className='counterval'>{count.toLocaleString()}+</h1>
        </div>
    );
};

export const PartsAvailabilityCounter = () => {
    const [percentage, setPercentage] = useState(0);
    const targetPercentage = 95;

    useEffect(() => {
        const interval = setInterval(() => {
            if (percentage < targetPercentage) {
                setPercentage(percentage + 1);
            } else {
                clearInterval(interval);
            }
        }, 10);

        return () => clearInterval(interval);
    }, [percentage, targetPercentage]);

    return (
        <div className="counter">
            <div className="fticon"><FontAwesomeIcon icon={faCog} size="2x" /></div>
            <h2>Filter Availability</h2>
            <h1 className='counterval'>{percentage}%</h1>
        </div>
    );
};
