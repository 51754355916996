import React from 'react';
import backgroundImage1 from './backgroundImage1.jpg'; // Import your background images
import backgroundImage2 from './backgroundImage2.jpg';
import backgroundImage3 from './backgroundImage3.jpg';
import backgroundvideo from './backgroundvideo.mp4'

export default function Carousel() {

    return (
        <div id="carouselExampleInterval" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
                <div class="carousel-item active">
                    <video className='bgvid' autoPlay loop muted>
                        <source src={backgroundvideo} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div className="carousel-item" data-bs-interval="10000">
                    <div className="background-image" style={{ backgroundImage: `url(${backgroundImage1})` }}></div>
                    <div className="first">
                        <p>We provide our customers with the best quality filters at a reasonable rate. Additionally, we take pride in nurturing strong and enduring relationships with our valued customers.</p>
                    </div>
                </div>
                <div className="carousel-item" data-bs-interval="2000">
                    <div className="background-image" style={{ backgroundImage: `url(${backgroundImage2})` }}></div>
                    <div className="first">
                        <p>Our commitment to excellence ensures that we deliver top-notch quality products at competitive rates, offering our customers unparalleled value.</p>
                    </div>
                </div>
                <div className="carousel-item">
                    <div className="background-image" style={{ backgroundImage: `url(${backgroundImage3})` }}></div>
                    <div className="first">
                        <p>With a product catalog boasting over 500,000 items, we've consistently nurtured strong customer relationships by delivering quality and affordability.</p>
                    </div>
                </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    );
}
