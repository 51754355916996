import React from 'react'
import './details.css'
import { Link } from 'react-router-dom';
import waterprocess from './waterprocess.jpg'
import 'slick-carousel/slick/slick.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the styles
import { Carousel } from 'react-responsive-carousel';
import 'slick-carousel/slick/slick-theme.css';
import water1 from './prodimg/water/water1.jpg'
import water2 from './prodimg/water/water2.jpg'
import water3 from './prodimg/water/water3.jpg'
import water4 from './prodimg/water/water4.jpg'
import water5 from './prodimg/water/water5.jpg'
import water6 from './prodimg/water/water6.jpg'
import water7 from './prodimg/water/water7.jpg'
import water8 from './prodimg/water/water8.jpg'
import water9 from './prodimg/water/water9.jpg'
import water10 from './prodimg/water/water10.jpg'
import water11 from './prodimg/water/water11.jpg'
import water12 from './prodimg/water/water12.jpg'

export default function Details2() {
    return (
        <div>
            <div className="filter-details">
                <h2>Water Separators</h2>

                <div className="information">
                    <h3>Product Information</h3>
                    <p>
                        A water separator filter, also known as a fuel-water separator, is a crucial component in various industries, particularly in automotive and marine applications. Its primary function is to separate water from a mixture of fuel and water that may be present in a system. This is of paramount importance as water in fuel can cause significant damage to engines and other mechanical components. Water separator filters are widely used in vehicles, boats, and industrial machinery to ensure the purity and efficiency of the fuel being used.
                    </p>
                    <h4>How does Water Separator work?</h4>
                    <ol>
                        <li>Inlet of Mixture: The mixture of fuel and water enters the water separator filter through an inlet.</li>
                        <li>Centrifugal Force: Once inside the filter, the mixture is subjected to centrifugal force. This force is generated by the flow of the mixture in a circular motion within the filter.</li>
                        <li>Density Difference: Due to the difference in density between water and fuel, the water particles tend to move outward towards the walls of the filter, while the fuel remains closer to the center.</li>
                        <li>Water Accumulation: As the water particles move outward, they collect along the walls of the filter. Here, they form larger droplets due to their coalescing nature.</li>
                        <li>Gravity Separation: The heavier water droplets, now separated from the fuel, fall to the bottom of the filter housing. There is typically a drain at the bottom to allow for periodic removal of the accumulated water.</li>
                        <li>Clean Fuel Outlet: The now purified fuel, which is largely free from water content, continues towards the outlet of the filter.</li>
                        <li>Final Filtration (if applicable): Depending on the specific design of the water separator filter, there may be additional filtration media to capture any remaining small water droplets.</li>
                        <li>Clean Fuel to Engine: The clean and water-free fuel is then supplied to the engine or machinery, ensuring optimal performance.</li>
                    </ol>
                    <img src={waterprocess} alt="waterprocess" className="process" />
                </div>

                <div className="specifications">
                    <h4>Summary</h4>
                    <ul>
                        <li>Water separator filters are specialized components used in fuel systems to remove water contaminants from fuel before it reaches the engine.</li>
                        <li>They operate on the principle of centrifugal force, utilizing the density difference between water and fuel to separate them.</li>
                        <li>As the fuel and water mixture enters the filter, it undergoes a circular motion, creating centrifugal force.</li>
                        <li>Water particles coalesce and accumulate along the walls of the filter, forming larger droplets.</li>
                        <li>By removing water from the fuel, these filters prevent potential engine damage and maintain optimal engine performance.</li>
                        <li>Regular inspection and maintenance of the water separator filter are crucial to ensure its effectiveness in preventing water contamination.</li>
                        <li>The purified fuel, largely free from water content, continues towards the outlet of the filter.</li>
                        <li>The filter typically includes a drainage system at the bottom to remove the accumulated water from the system.</li>
                        <li>In the marine industry, water separator filters play a critical role in preventing water-induced damage to engines, which is especially vital for boats and ships.</li>
                        <li>Water separator filters are commonly used in diesel engines, marine engines, and other heavy machinery where water contamination is a concern.</li>
                        {/* Add more specifications */}
                    </ul>
                </div>

                <div className="image-card-container">
                    <Carousel
                        autoPlay
                        interval={2000}
                        infiniteLoop
                        showThumbs={false}
                        showArrows={false}
                        centerMode
                        centerSlidePercentage={33.3}
                        emulateTouch
                        showStatus={false}
                        showIndicators={false} // Add this line to hide the sliding dots
                    >
                        <div className='image-card'>
                            <img src={water1} alt="" />
                        </div>
                        <div className='image-card'>
                            <img src={water2} alt="" />
                        </div>
                        <div className='image-card'>
                            <img src={water3} alt="" />
                        </div>
                        <div className='image-card'>
                            <img src={water4} alt="" />
                        </div>
                        <div className='image-card'>
                            <img src={water5} alt="" />
                        </div>
                        <div className='image-card'>
                            <img src={water6} alt="" />
                        </div>
                        <div className='image-card'>
                            <img src={water7} alt="" />
                        </div>
                        <div className='image-card'>
                            <img src={water8} alt="" />
                        </div>
                        <div className='image-card'>
                            <img src={water9} alt="" />
                        </div>
                        <div className='image-card'>
                            <img src={water10} alt="" />
                        </div>
                        <div className='image-card'>
                            <img src={water11} alt="" />
                        </div>
                        <div className='image-card'>
                            <img src={water12} alt="" />
                        </div>
                    </Carousel>
                </div>
            </div>
            <Link to="/WaterSeparator">
                <h4 className='morefilters'>Click here to see all types of Fuel-Water Separator</h4>
            </Link>
        </div>
    )
}
